import { Expose, Type } from 'class-transformer';

// Model

export class RoleLiteModel {
  id: string;
  name: string;
}

export class EntityLiteModel extends RoleLiteModel {}

export class AccountLiteModel extends EntityLiteModel {}
export class EntityModel extends EntityLiteModel {
  accounts: AccountLiteModel[];
}
export class RoleModel {
  id: string;
  name: string;

  @Expose({ name: 'permission_groups' })
  permissionGroups: string[];
}

export class PermissionGroupModel {
  id: string;
  name: string;
}

export class PermissionCategoryModel {
  category: string;

  @Expose({ name: 'permission_groups' })
  @Type(() => PermissionGroupModel)
  permissionGroups: PermissionGroupModel[];
}

// Input

export class getRolesInput {
  params: {
    page: number;
    limit?: number;
    q?: string;
  };
}

export type getRoleInput = {
  id: string;
};

export type RoleCreateInput = {
  name: string;
  permissions: string[];
};

export type RoleMutationInput = {
  name: string;
  permissions: string[];
};

export type RoleUpdateInput = {
  roleId: string;
  body: {
    name: string;
    permissions: string[];
  };
};

export class RoleDeleteInput {
  @Expose({ name: 'roleId' })
  roleId: string;
}
