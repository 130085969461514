import { getIndexInput } from 'api-hooks/common';

// Model
export class DivisionLiteModel {
  id: string;
  name: string;
}

export class DivisionModel extends DivisionLiteModel {}

// Input
export type getDivisionInput = {
  id: string;
};

export type getDivisionsInput = getIndexInput;

export type DivisionMutationInput = {
  name: string;
};

export type DivisionUpdateInput = {
  divisionId: string;
  body: DivisionMutationInput;
};
export type DivisionDeleteInput = {
  divisionId: string;
};
