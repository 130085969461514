import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  DivisionLiteModel,
  DivisionModel,
  getDivisionInput,
  getDivisionsInput,
} from './model';

export function useGetDivisions(
  input?: getDivisionsInput,
  options?: UseQueryOptions<ExtendedApiResult<DivisionLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<DivisionLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getDivisions', input?.params],
      () =>
        QueryFetchFunction({
          url: 'divisions',
          params: input?.params,
        }),
      options,
    ),
    DivisionLiteModel,
  );
}

export function useGetDivisionsByEmployee(
  input?: getDivisionsInput,
  options?: UseQueryOptions<ExtendedApiResult<DivisionLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<DivisionLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getDivisionsByEmployee', input?.params],
      () =>
        QueryFetchFunction({
          url: 'divisions/employee',
          params: input?.params,
        }),
      options,
    ),
    DivisionLiteModel,
  );
}

export function useGetDivision(
  input?: getDivisionInput,
  options?: UseQueryOptions<ApiResult<DivisionModel>, ApiError>,
): UseQueryResult<ApiResult<DivisionModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getDivision', input?.id],
      () =>
        QueryFetchFunction({
          url: `divisions/${input?.id}`,
        }),
      options,
    ),
    DivisionModel,
  );
}

export function getDivisionsKey(input?: getDivisionsInput) {
  return ['getDivisions', input?.params];
}

export function getDivisionsByEmployeeKey(input?: getDivisionsInput) {
  return ['getDivisionsByEmployee', input?.params];
}

export function getDivisionKey(input?: getDivisionInput) {
  return ['getDivision', input?.id];
}
