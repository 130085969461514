import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  DivisionDeleteInput,
  DivisionModel,
  DivisionMutationInput,
  DivisionUpdateInput,
} from './model';

export function useCreateDivision(
  options?: UseMutationOptions<
    ApiResult<DivisionModel>,
    ApiError,
    DivisionMutationInput
  >,
): UseMutationResult<
  ApiResult<DivisionModel>,
  ApiError,
  DivisionMutationInput
> {
  return useMutation<ApiResult<DivisionModel>, ApiError, DivisionMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'divisions',
        method: 'POST',
        classType: DivisionModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateDivision(
  options?: UseMutationOptions<
    ApiResult<DivisionModel>,
    ApiError,
    DivisionUpdateInput
  >,
): UseMutationResult<ApiResult<DivisionModel>, ApiError, DivisionUpdateInput> {
  return useMutation<ApiResult<DivisionModel>, ApiError, DivisionUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `divisions/${body.divisionId}`,
        method: 'PUT',
        classType: DivisionModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteDivision(
  options?: UseMutationOptions<MessageResult, ApiError, DivisionDeleteInput>,
): UseMutationResult<MessageResult, ApiError, DivisionDeleteInput> {
  return useMutation<MessageResult, ApiError, DivisionDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `divisions/${body.divisionId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
