import { BrandModel } from 'api-hooks/brand';
import { getIndexInput } from 'api-hooks/common';
import { DivisionModel } from 'api-hooks/division';
import { EntityLiteModel, RoleLiteModel } from 'api-hooks/role/model';
import { Expose, Type } from 'class-transformer';

// Model
export class Employee2FAModel {
  @Expose({ name: 'google_qr_code' })
  googleQrCode: string;
  @Expose({ name: 'google2fa_secret' })
  google2faSecret: string;
}

export class EmployeeLiteModel {
  id: string;
  name: string;
  email: string;
  division: DivisionModel;

  @Expose({ name: 'telegram_chat_id' })
  telegramChatId: string;

  @Type(() => RoleLiteModel)
  roles: RoleLiteModel[];

  @Type(() => EntityLiteModel)
  entities: EntityLiteModel[];

  @Expose({ name: 'is_active' })
  isActive: boolean;
}

export class EmployeeEntityModel {
  id: string;
  name: string;
  currency: string;

  @Expose({ name: 'currency_id' })
  currencyId: string;
}

export class EntityEmployeeModel {
  id: string;
  name: string;
  currency: string;

  @Type(() => BrandModel)
  brands: BrandModel[];

  @Expose({ name: 'is_all_account' })
  isAllAccount: boolean;
}
export class EntityEmployeeMutationModel {
  id: string;
  isAllAccount: boolean;
  accounts: string[];
}

export class EmployeeModel {
  id: string;
  name: string;
  email: string;
  division: DivisionModel;

  @Expose({ name: 'is_active' })
  isActive: boolean;

  @Expose({ name: 'telegram_chat_id' })
  telegramChatId: string;

  @Type(() => RoleLiteModel)
  roles: RoleLiteModel[];

  @Type(() => EntityEmployeeModel)
  entities: EntityEmployeeModel[];
}

// Input

export type getEmployeesInput = getIndexInput;
export type getEmployeeInput = {
  id: string;
};

export type EmployeeMutationInput = {
  name: string;
  email: string;
  password?: string;
  passwordConfirmation?: string;
  roles: string[];
  entities: EntityEmployeeMutationModel[];
  telegramId: string;
  divisionId: string;
};

export type EmployeeUpdateInput = {
  employeeId: string;
  body: EmployeeMutationInput;
};

export type EmployeeDeleteInput = {
  employeeId: string;
};

export type Employee2FAInput = {
  employeeId: string;
};

export type EmployeeChangePasswordInput = {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};

export type EmployeeUpdateStatusInput = {
  isActive: boolean;
  employeeId: string;
};
