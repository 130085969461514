import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getRolesInput,
  getRoleInput,
  RoleLiteModel,
  RoleModel,
  PermissionCategoryModel,
} from './model';

export function useGetRoles(
  input?: getRolesInput,
  options?: UseQueryOptions<ExtendedApiResult<RoleLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<RoleLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getRoles', input?.params],
      () =>
        QueryFetchFunction({
          url: 'roles',
          params: input?.params,
        }),
      options,
    ),
    RoleLiteModel,
  );
}

export function useGetRole(
  input?: getRoleInput,
  options?: UseQueryOptions<ApiResult<RoleModel>, ApiError>,
): UseQueryResult<ApiResult<RoleModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getRole', input?.id],
      () => QueryFetchFunction({ url: `roles/${input?.id}` }),
      options,
    ),
    RoleModel,
  );
}

export function getRolesKey(input?: getRolesInput) {
  return ['getRoles', input?.params];
}

export function getRoleKey(input?: getRoleInput) {
  return ['getRole', input?.id];
}

export function useGetPermissionGroups(
  options?: UseQueryOptions<
    ExtendedApiResult<PermissionCategoryModel[]>,
    ApiError
  >,
) {
  return QueryTransformer(
    useQuery(
      ['getPermissionGroups'],
      () => QueryFetchFunction({ url: 'permission-groups' }),
      options,
    ),
    PermissionCategoryModel,
  );
}

export function getPermissionGroupsKey() {
  return ['getPermissionGroups'];
}
