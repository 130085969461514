module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': [
      'common',
      'navigation',
      'account',
      'employee',
      'currency',
      'entity',
      'transfer',
      'modal',
      'role',
      'budgeting',
      'error',
      'inventory',
      'report',
      'transaction',
      'pr',
      'approval',
    ],
  },
  localeDetection: false,
  loader: false,
};
