import { Expose, Type } from 'class-transformer';

// Enum
export enum ColumnEnum {
  string = 'string',
  number = 'number',
  integer = 'integer',
  date = 'date',
  nested = 'nested',
}

export enum PivotAggregateFormatEnum {
  money = 'money',
  numeric = 'numeric',
  percentage = 'percentage',
}

export enum PhoneTypeEnum {
  mobile,
  office,
  whatsapp,
}

// Model
export class EnumResultModel {
  label: string;
  value: string;
}

export class ImageClass {
  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;

  id: string;
  name: string;
  url: string;
}

export class FileResultModel {
  id: string;
  name: string;
  url: string;
}

export type FileInputType = {
  id?: string;
  file: string | null;
  url?: string | null;
};

export class CommonModel {
  id: string;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class CommonSimpleModel {
  id: string;
  name: string;
}

export class PivotAggregateRowModel {
  name: string;
  label: string;
  link?: string | null;
  badge?: string | null;
  array_accessor?: string;

  format: PivotAggregateFormatEnum;
}

export class PivotRowModel {
  name: string;
  label: string;
  link?: string | null;
  badge?: string | null;
  background?: string | null;
  array_accessor?: string;
  hide?: boolean;

  @Expose({ name: 'show_total' })
  @Type(() => Boolean)
  showTotal: boolean;
}

export class PivotItemModel {
  @Type(() => PivotAggregateRowModel)
  aggregates: PivotAggregateRowModel[];
  @Type(() => PivotRowModel)
  rows: PivotRowModel[];
}

export class PivotModel {
  @Type(() => PivotItemModel)
  default: PivotItemModel;
  @Type(() => PivotItemModel)
  value: PivotItemModel;

  @Expose({ name: 'is_editable' })
  @Type(() => Boolean)
  isEditable: boolean;
}

export class ColumnReport {
  name: string;
  label: string;
  type: ColumnEnum;
  columns?: ColumnReport[];
}

export class TimeModel {
  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class ApiError {
  message: string;
  statusCode?: number;
  errors?: { [key: string]: string };
}

export class MessageResult {
  message: string;
}

export enum FilterType {
  Text = 'text',
  Number = 'number',
  Option = 'option',
  Date = 'date',
}

export enum FilterBehaviour {
  Exact = 'exact',
  Partial = 'partial',
  Range = 'range',
  Single = 'single',
  Multiple = 'multiple',
  Before = 'before',
  After = 'after',
}

export class Option {
  label: string;
  value: string;
}

export class Filter {
  name: string;
  label: string;
  type: string;

  @Type(() => Option)
  options?: Option[];

  behaviour: FilterBehaviour;
  value?: string | null;
  default?: string;
}
export class Sort {
  options: string[];
  default: string;
  value?: string;
}

export class PaginationMeta {
  to: number;
  total: number;
  from: number;
  path: string;

  @Expose({ name: 'current_page' })
  currentPage: number;

  @Expose({ name: 'last_page' })
  lastPage: number;

  @Expose({ name: 'per_page' })
  perPage: number;
}

export class ApiResult<T> {
  data: T;
  message?: string;
}

export class ExtendedApiResult<T> extends ApiResult<T> {
  @Type(() => PaginationMeta)
  meta: PaginationMeta;
  @Type(() => Filter)
  filters: Filter[];
  @Type(() => Sort)
  sorts: Sort;
  @Type(() => ColumnReport)
  columns?: ColumnReport[];
}

// Input
export class getEnumsInput {
  class: string;
}

export class getFilesInput {
  params: string;
}

export interface getIndexInput<Params extends { [key: string]: any } = object> {
  params: {
    q?: string;
    page?: number;
    filter?: { [key: string]: any };
    sort?: string;
    limit?: number;
  } & Params;
}
