import { DefaultMantineColor } from '@mantine/core';
import { getIndexInput } from 'api-hooks/common';

// Model
export class BrandLiteModel {
  id: string;
  name: string;
  color: DefaultMantineColor;
}

export class BrandModel extends BrandLiteModel {}

// Input
export type getBrandInput = {
  id: string;
};

export type getAllBrandInput = {
  entityId: string;
  model?: string;
};

export type getBrandsInput = getIndexInput;

export type BrandMutationInput = {
  name: string;
  color: string;
};

export type BrandUpdateInput = {
  brandId: string;
  body: BrandMutationInput;
};
export type BrandDeleteInput = {
  brandId: string;
};
