import { DivisionModel } from 'api-hooks/division';
import { EmployeeEntityModel } from 'api-hooks/employee/model';
import { RoleModel } from 'api-hooks/role';
import { Expose, Type } from 'class-transformer';

// Model

export class UserModel {
  id: string;
  name: string;
  email: string;

  @Type(() => EmployeeEntityModel)
  entities: EmployeeEntityModel[];

  @Type(() => RoleModel)
  roles: RoleModel[];

  @Type(() => DivisionModel)
  division: DivisionModel;

  @Expose({ name: 'is_active' })
  @Type(() => Boolean)
  isActive: boolean;

  @Expose({ name: 'google_qr_code' })
  googleQrCode?: string | null;
  @Expose({ name: 'google_secret_key' })
  googleSecretKey?: string | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;

  @Expose({ name: 'deleted_at' })
  @Type(() => Date)
  deletedAt: Date;
}

export class TokenResultModel {
  @Expose({ name: 'token_type' })
  tokenType: string;

  @Expose({ name: 'expires_in' })
  expiresIn: number;

  @Expose({ name: 'access_token' })
  accessToken: string;

  @Expose({ name: 'refresh_token' })
  refreshToken: string;
}

export class LoginMutationInput {
  username: string;
  password: string;
  otp?: string;
}

export class RefreshTokenMutationInput {
  refreshToken: string;
}
