import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';

import {
  BrandLiteModel,
  BrandModel,
  getAllBrandInput,
  getBrandInput,
  getBrandsInput,
} from './model';

export function useGetBrands(
  input?: getBrandsInput,
  options?: UseQueryOptions<ExtendedApiResult<BrandLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<BrandLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getBrands', input?.params],
      () =>
        QueryFetchFunction({
          url: 'brands',
          params: input?.params,
        }),
      options,
    ),
    BrandLiteModel,
  );
}

export function useGetBrand(
  input?: getBrandInput,
  options?: UseQueryOptions<ApiResult<BrandLiteModel>, ApiError>,
): UseQueryResult<ApiResult<BrandLiteModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getBrand', input?.id],
      () =>
        QueryFetchFunction({
          url: `brands/${input?.id}`,
        }),
      options,
    ),
    BrandModel,
  );
}

export function useGetAllBrand(): UseMutationResult<
  ApiResult<any>,
  ApiError,
  getAllBrandInput
> {
  return useMutation<ApiResult<any>, ApiError, getAllBrandInput>((body) =>
    MutationFetchFunction({
      url: 'brands',
      method: 'GET',
      params: {
        limit: -1,
        filter: {
          entity_id: body.entityId,
          ...(!!body.model && { model: body.model }),
        },
      },
    }),
  );
}

export function getBrandsKey(input?: getBrandsInput) {
  return ['getBrands', input?.params];
}

export function getBrandKey(input?: getBrandInput) {
  return ['getBrand', input?.id];
}
