import * as React from 'react';

interface EntityProviderProps {
  children: React.ReactNode;
}
interface EntityModel {
  id: string;
  name: string;
  currency: string;
  currencyId: string;
}
interface WarehouseResult {
  entity: EntityModel | null;
  setEntity: (value: EntityModel | null) => void;
}

const context = React.createContext<WarehouseResult>({
  entity: null,
  setEntity() {},
});

const { Provider } = context;

export function EntityProvider(props: EntityProviderProps) {
  const [entity, setState] = React.useState<EntityModel | null>(null);
  const setEntity = React.useCallback(async (value: EntityModel | null) => {
    setState(value);
    if (window) {
      localStorage.setItem('entity', JSON.stringify(value));
    }
  }, []);

  React.useEffect(() => {
    async function exec() {
      if (window) {
        const getLocalEntity = await localStorage.getItem('entity');
        if (getLocalEntity) {
          const localEntity = JSON.parse(getLocalEntity);
          setState(
            localEntity
              ? {
                  id: localEntity.id,
                  name: localEntity.name,
                  currency: localEntity.currency,
                  currencyId: localEntity.currencyId,
                }
              : null,
          );
        } else {
          setState(null);
        }
      }
    }
    exec();
  }, []);

  return <Provider value={{ entity, setEntity }}>{props.children}</Provider>;
}

export function useEntity() {
  return React.useContext(context);
}
