import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  RoleModel,
  RoleCreateInput,
  RoleDeleteInput,
  RoleUpdateInput,
} from './model';

export function useCreateRole(
  options?: UseMutationOptions<ApiResult<RoleModel>, ApiError, RoleCreateInput>,
): UseMutationResult<ApiResult<RoleModel>, ApiError, RoleCreateInput> {
  return useMutation<ApiResult<RoleModel>, ApiError, RoleCreateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'roles',
        method: 'POST',
        classType: RoleModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateRole(
  options?: UseMutationOptions<ApiResult<RoleModel>, ApiError, RoleUpdateInput>,
): UseMutationResult<ApiResult<RoleModel>, ApiError, RoleUpdateInput> {
  return useMutation<ApiResult<RoleModel>, ApiError, RoleUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `roles/${body.roleId}`,
        method: 'PUT',
        classType: RoleModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteRole(
  options?: UseMutationOptions<MessageResult, ApiError, RoleDeleteInput>,
): UseMutationResult<MessageResult, ApiError, RoleDeleteInput> {
  return useMutation<MessageResult, ApiError, RoleDeleteInput>(async function (
    body,
  ) {
    return await MutationFetchFunction({
      url: `roles/${body.roleId}`,
      method: 'DELETE',
    });
  },
  options);
}
