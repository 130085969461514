import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  BrandDeleteInput,
  BrandModel,
  BrandMutationInput,
  BrandUpdateInput,
} from './model';

export function useCreateBrand(
  options?: UseMutationOptions<
    ApiResult<BrandModel>,
    ApiError,
    BrandMutationInput
  >,
): UseMutationResult<ApiResult<BrandModel>, ApiError, BrandMutationInput> {
  return useMutation<ApiResult<BrandModel>, ApiError, BrandMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'brands',
        method: 'POST',
        classType: BrandModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateBrand(
  options?: UseMutationOptions<
    ApiResult<BrandModel>,
    ApiError,
    BrandUpdateInput
  >,
): UseMutationResult<ApiResult<BrandModel>, ApiError, BrandUpdateInput> {
  return useMutation<ApiResult<BrandModel>, ApiError, BrandUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `brands/${body.brandId}`,
        method: 'PUT',
        classType: BrandModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteBrand(
  options?: UseMutationOptions<MessageResult, ApiError, BrandDeleteInput>,
): UseMutationResult<MessageResult, ApiError, BrandDeleteInput> {
  return useMutation<MessageResult, ApiError, BrandDeleteInput>(async function (
    body,
  ) {
    return await MutationFetchFunction({
      url: `brands/${body.brandId}`,
      method: 'DELETE',
    });
  },
  options);
}
