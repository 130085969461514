import { createModel } from '@rematch/core';
import { UserModel } from 'api-hooks/auth/model';
import { getMe, getPermission } from 'api-hooks/auth/query';
import { produce } from 'immer';

import { RootState } from '.';

export interface AuthState {
  data?: UserModel;
  permissions?: string[];
  isPermissionFetched?: boolean;
}

const initialState: AuthState = {
  data: undefined,
  permissions: undefined,
  isPermissionFetched: undefined,
};

const auth = createModel()({
  name: 'auth',
  state: initialState,
  reducers: {
    setUser(state: AuthState, payload: UserModel): AuthState {
      return produce(state, (draft) => {
        draft.data = payload;
      });
    },
    setPermission(state: AuthState, payload: string[]): AuthState {
      return produce(state, (draft) => {
        draft.permissions = payload;
      });
    },
    setPermissionFetched(state: AuthState): AuthState {
      return produce(state, (draft) => {
        draft.isPermissionFetched = true;
      });
    },
    reset(): AuthState {
      return {
        ...initialState,
      };
    },
  },
  effects: (dispatch) => {
    const { auth } = dispatch;
    return {
      async getMe(): Promise<UserModel | undefined> {
        try {
          const user = await getMe();
          auth.setUser(user);
          return user;
        } catch {}
      },
      async getPermission() {
        try {
          const user = await getMe();
          auth.setUser(user);

          const permissions = await getPermission();
          auth.setPermission(permissions);

          auth.setPermissionFetched();
        } catch {}
      },
    };
  },
});

// Selector
const userSelector = (state: RootState) => state.auth.data;
const permissionSelector = (state: RootState) => state.auth.permissions;
const isPermissionFetchedSelector = (state: RootState) =>
  state.auth.isPermissionFetched;
const isFetchingPermission = (state: RootState): boolean =>
  (state as any).loading.effects.auth.getPermission;
const isFetchingGetMe = (state: RootState): boolean =>
  (state as any).loading.effects.auth.getMe;

export const authSelector = {
  userSelector,
  permissionSelector,
  isPermissionFetchedSelector,
  isFetchingPermission,
  isFetchingGetMe,
};

export const authDispatcher = (dispatch: any) => {
  return {
    getMe: async (): Promise<UserModel | undefined> => {
      return await dispatch.auth.getMe();
    },
    getPermission: async () => {
      await dispatch.auth.getPermission();
    },
    reset: async () => {
      await dispatch.auth.reset();
    },
  };
};

export default auth;
