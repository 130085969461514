import { resetGlobal } from 'common/global';
import { queryClient } from 'common/repositories/query-client';
import { useRouter } from 'next/router';

import { useEntity } from './use-entities';
import { useKY } from './use-ky';

export default function useLogout() {
  const { setEntity } = useEntity();
  const { setCredential, setRedirectLogout } = useKY();

  const { push } = useRouter();

  const logout = async () => {
    resetGlobal();
    await localStorage.removeItem('auth.session-token');
    await localStorage.removeItem('entity');
    await queryClient.invalidateQueries();
    await queryClient.clear();
  };

  const handleLogout = async () => {
    try {
      await setEntity({ id: '', name: '', currency: '', currencyId: '' });
      await setRedirectLogout?.(true);
      await setCredential(undefined);
      await logout();
      push('/login');
    } catch (e) {
      console.error(e);
    }
  };

  return { logout, handleLogout };
}
